<template>
  <div>
    <headerNav activeIndex="5"></headerNav>
    <div class="contactBody">
      <div class="contactImg"></div>
      <div class="contactTitle">联系我们</div>
      <div class="contactText">Online message</div>
      <div class="contactForm">
        <el-form ref="form" :model="form" label-width="140px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="您的姓名：">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话：">
                <el-input v-model="form.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="详情描述：">
                <el-input type="textarea"  resize="none" maxlength="300" v-model="form.remark"  :autosize="{ minRows: 5}" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-button type="primary" class="formBtn" @click="submitted">提交咨询</el-button>
        </el-form>
      </div>
      <div class="contactTitle">其他方式</div>
      <div class="contactText">Contact information</div>
      <div class="contactMap">
        <div class="mapImg">
          <el-amap :vid="'amap'" :zoom="15" :center="center">
            <el-amap-marker :position="center"></el-amap-marker>
          </el-amap>
        </div>
        <div class="contactDiv">
          <div class="mapTitle">袁 总</div>
          <div class="mapLabel">联系方式</div>
          <div class="mapSpan">180-7313-1810</div>
          <div class="mapLabel" style="margin-top:40px">地址</div>
          <div class="mapSpan">湖南省长沙市望城区金桥国际未来城3栋2层</div>
        </div>
      </div>
    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from "@/components/headerNav.vue";
import footerNav from "@/components/footerNav.vue";
import { mapState, mapActions } from "vuex";
export default {
  name:'contact',
  components: {
    headerNav,
    footerNav
  },
  data() {
    return {
      center: [112.838943,28.292878],
      form:{
        name:'',
        phone:'',
        remark:'',
      },
    };
  },
  methods: {
    submitted(){
      if(!this.form.name){
        this.$message({
          message: '请输入您的姓名!',
          type: 'warning'
        });
        return;
      }
      if(!this.form.phone){
        this.$message({
          message: '请输入您的联系方式!',
          type: 'warning'
        });
        return;
      }
      if(!this.form.remark){
        this.$message({
          message: '请输入您的详情描述!',
          type: 'warning'
        });
        return;
      }
      this.form.name = ''
      this.form.phone = ''
      this.form.remark = ''
      this.$message({
        message: '提交成功!',
        type: 'success'
      });
    },
  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
.contactBody{
  width:100%;
  min-height: 800px;
  .contactImg{
    width:100%;
    height:427px;
    background:url("../../assets/img/contactImg.png") no-repeat center;
  }
  .contactTitle{
    width:100%;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 40px;
    color: #050829;
    line-height: 56px;
    letter-spacing: 1px;
    text-align: center;
    font-style: normal;
    margin-top: 60px;
  }
  .contactText{
    width:100%;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #999999;
    line-height: 25px;
    text-align: center;
    font-style: normal;
    text-transform: uppercase;
    margin-top: 10px;
  }
  .contactMap{
    width: 1200px;
    height:447px;
    margin:0px auto;
    margin-top: 50px;
    margin-bottom: 120px;
    display: flex;
    justify-content:space-between;
    .mapImg{
      width: 674px;
      height: 447px;
      //background:url("../../assets/img/mapImg.png") no-repeat center;
      //background-size: 100% 100%;
    }
    .contactDiv{
      width: 476px;
      height: 302px;
      background: #FFFFFF;
      box-shadow: 0px 0px 20px 0px rgba(215,224,255,0.5);
      margin-top: 53px;
      .mapTitle{
        width: 396px;
        height: 80px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #202D37;
        line-height: 80px;
        text-align: left;
        font-style: normal;
        margin:0 auto;
        border-bottom: 1px solid #E7E7E7;
      }
      .mapLabel{
        margin:0 auto;
        margin-top: 30px;
        width: 396px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #999999;
        line-height: 21px;
        font-style: normal;
        text-align: left;
      }
      .mapSpan{
        margin:0 auto;
        margin-top: 15px;
        width: 396px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #202D37;
        line-height: 21px;
        text-align: left;
        font-style: normal;
      }
    }
  }
  .contactForm{
    width: 1200px;
    margin:0px auto;
    margin-top: 50px;
    .formBtn{
      width: 550px;
      height: 50px;
      border-radius: 8px;
      margin-left:140px;
      margin-top: 30px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
      font-style: normal;
    }
  }
}
</style>
